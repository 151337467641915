<template>
  <div class="authentication" :class="[viewData.status == 0? 'eventNone' : '',viewData.status == 2? 'eventNone' : '']">
    <div class="artItem">
      <h1>认证发声人标签</h1>
      <van-field
        v-model="tag"
        is-link
        readonly
        name="picker"
        label
        placeholder="点击选择标签"
        @click="showPicker = true"
      />
      <van-popup v-model:show="showPicker" position="bottom">
        <van-picker :columns="columns" @confirm="onConfirm" @cancel="showPicker = false" />
      </van-popup>
    </div>
    <div class="artItem">
      <h1>申请理由</h1>
      <van-field type="textarea" v-model="viewData.reason" name="name" label placeholder="请您输入申请理由" />
    </div>
    <!-- 状态：1：拒审  显示驳回理由-->
    <div v-show="viewData.status == 1" class="artItem noBefore" style="padding-bottom:0.4rem;">
      <h1>驳回理由</h1>
      <p>{{viewData.remark}}</p>
    </div>
    <div class="submitHold" v-show="viewData.status == undefined || viewData.status == 1">
      <van-button :loading="submitLoading" round plain class="submit" type="info" @click="submit">提交</van-button>
    </div>
    <div style="height:3rem;"></div>
    <!-- 底部导航 -->
    <index-footer style="pointer-events: auto;"></index-footer>
    <van-overlay :show="loadShow" style="z-index:900;">
      <div class="wld-wrapper" @click.stop>
        <div class="wld-block">
          <!-- <van-loading type="spinner" /> -->
          <van-loading size="24px" vertical>加载中...</van-loading>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
// @ is an alias to /src
import indexFooter from "./footer.vue";
import { Notify } from "vant";
import Cookies from "js-cookie";

export default {
  name: "apply_speaker",
  setup() {
    const onClickLeft = function() {
      history.back();
    };
    return {
      onClickLeft
    };
  },
  data() {
    return {
      loadNum: 0,
      loadShow: false,
      submitLoading: false, // 按钮加载状态
      showPicker: false,
      columns: [],
      tag:'',
      tagList:'',
      viewData:{
        tag_id:'',
        reason:'',
      }
    };
  },
  components: {
    indexFooter
  },
  created() {
    console.log(this.viewData.status)
    // 获取 认证发生人 的标签
    let that = this;
    this.loadNum++;
    this.loadShow = true;
    let ps = {
      token: Cookies.get("spToken")
    };
    that.$api.index.getTags(ps).then(function(response) {
      if (response.code === 200) {
        that.loadNum--;
        if (that.loadNum == 0) {
          that.loadShow = false;
        }
        that.tagList = response.result;
        let arr = [];
        that.tagList.forEach(function(item, idx) {
          arr.push(item.name);
        });
        // console.log(response.result)
        that.columns = arr;
        // console.log(that.columns)
      }
    });
    // 获取详情
    this.loadNum++;
    this.loadShow = true;
    this.getDetail()
  },
  methods: {
    getDetail(){
      let that = this;
      let ps = {
        token: Cookies.get("spToken")
      };
      that.$api.index.getDetail(ps).then(function(response) {
        if (response.code === 200) {
          that.loadNum--;
          if (that.loadNum == 0) {
            that.loadShow = false;
          }
          // result 没有返回值时就是新增。 没认证过
          if(!response.result){
            return
          }
          // 有返回值 根据认证状态进去显示页面 控制
          that.viewData = response.result
          that.tag = that.viewData.speaker_sptag ? that.viewData.speaker_sptag.name : ''
        }
      })
    },
    onConfirm(value) {
      this.tag = value;
      this.showPicker = false;
    },
    submit() {
      let that = this;
      that.submitLoading = true;
      that.tagList.forEach(function(item, idx) {
        if (item.name == that.tag) {
          that.viewData.tag_id = item.id;
        }
      });
      let ps = {
        id:this.viewData.id,
        reason: this.viewData.reason,
        tag_id: this.viewData.tag_id,
        token: Cookies.get("spToken")
      };
      console.log(ps);
      // 如果有id就是编辑
      if(this.viewData.id) {
        that.$api.index.applytagEdit(ps).then(function(response) {
          if (response.code === 200) {
            that.$toast("操作成功")
            setTimeout(() => {
              that.$router.back(-1)
            }, 1500);
          }
        })
        return
      }
      // 没有id就是新增
      that.$api.index.applytagCreat(ps).then(function(response) {
        that.submitLoading = false;
        if (response.code === 200) {
          // console.log(response.result)
          // Notify({ type: "success", message: "操作成功" });
          that.$toast("操作成功")
          setTimeout(() => {
            that.$router.back(-1)
          }, 1500);
        }
      });
      // that.toPath("/my", {});//注掉
    },
    toPath(url, obj) {
      if (this.$route.fullPath == url) {
        return;
      }
      if (this.$route.path == url) {
        this.$router.push({
          path: url,
          query: obj
        });
        window.location.reload();
        return;
      }
      this.$router.push({
        path: url,
        query: obj
      });
    }
  },
  mounted() {
  }
};
</script>
<style>
  .eventNone{
    pointer-events: none;
  }
  .authentication .artItem {
    padding: 0.4rem 0.68rem;
    padding-right: 0.52rem;
    padding-bottom: 0.1rem;
    width: 9.2rem;
    margin: 0 auto;
    box-shadow: 0 0 20px 0 rgb(50 93 205 / 15%);
    margin-top: 0.32rem;
  }
  .authentication .artItem p{
    font-size: 0.32rem;
    color: #e83333;
    padding-top: 0.2rem;
  }
  .authentication .artItem .van-cell{
    padding-left: 0;
  }
  .authentication .artItem h1 {
      font-size: 0.37rem;
      font-family: PingFang SC;
      font-weight: 400;
      color: #000000;
      position: relative;
  }
  .authentication .artItem h1:before {
    content: "*";
    position: absolute;
    top: 3px;
    left: -15px;
    color: #e83333;
  }
  .authentication .artItem.noBefore h1 {
    padding-left: 0;
  }
  .authentication .artItem.noBefore h1:before {
    display: none;
  }
  .authentication .submitHold {
    width: 6rem;
    margin: 0 auto;
    margin-top: 1rem;
  }
  .authentication .submitHold .submit {
    width: 100%;
    border: none;
    background: #0b47ac;
    font-size: 0.45rem;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
  }
</style>
